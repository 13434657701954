<template>
  <div class="block__subtitle mb-40" v-if="!$route.params.id">{{ title }}</div>
  <div class="block__tabs mb-30" v-if="!$route.params.id">
    <VTabs :is-small="true" :items="securityAccreditation "/>
  </div>
  <div class="block__control" v-if="!$route.params.id">
    <div class="block__field">
      <VSearch
        v-model="input"
        name="searchAccreditation"
        placeholder="Поиск"
        class-input="input-border-all input-padding-25 input-height-48" />
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import VSearch from '@/components/ui/form/Search/VSearch'
import VTabs from '@/components/views/Tabs/VTabs'
import { securityAccreditation } from '@/utils/tabs'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useFilter } from '@/use/Base/useFilter'
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const route = useRoute()

    const getRoute = computed(() => {
      return route.fullPath === '/security/accreditation/archive'
    })
    return {
      securityAccreditation,
      getRoute,
      ...useFilter()
    }
  },
  components: {
    VSearch,
    VTabs
  }
}
</script>
